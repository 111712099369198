import axios from "axios"
const baseApiUrl = process.env.REACT_APP_BASE_API_URL
const localToken = process.env.REACT_APP_LOCAL_STORAGE

export const axiosInterceptor = (navigate) => {
    axios.interceptors.response.use(
        res => {
            // Check if the request was a login and contains a token
            if (res.config.url === `${baseApiUrl}/user/login` 
                && res.headers['authorization']
            ) {
                // Extract the token from the header and store it
                const token = res.headers['authorization'].split(" ")[1]
                localStorage.setItem(localToken, JSON.stringify(token))
                navigate("/");
            }

            return res
        },
        err => {
            // Handle 401 Unauthorized errors
            if (err.response.status === 401) {
                localStorage.removeItem(localToken);
                navigate("/login");
            }

            return Promise.reject(err)
        }
    )

    axios.interceptors.request.use(
        req => {
            // For all post, patch and delete requests except the login endpoint, add the Authorization header
            if (req.method !== "get" && req.url !== `${baseApiUrl}/user/login`) {
                const localStorageAuthorization = `Bearer ${JSON.parse(localStorage.getItem(localToken))}`
                req.headers["authorization"] = localStorageAuthorization;
            }
            return req
        },
        err => {
            return Promise.reject(err)
        }
    )
}