import "./index.css"
import axios from "axios"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'

export default function Login() {
    const navigate = useNavigate()
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL
    const startDrawActive = process.env.REACT_APP_START_DRAW
    const localToken = process.env.REACT_APP_LOCAL_STORAGE

    function passwordHidden() {
        var input = document.getElementById("password");
        if (input.type === "password") {
            input.type = "text";
        } else {
            input.type = "password";
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const user_name = e.target.elements.user_name.value
        const password = e.target.elements.password.value

        try {
            const res = await axios.post(`${baseApiUrl}/user/login`, {
                user_name,
                password
            })
            if (res.status === 200) {
                toast.success(res.data?.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Server Cavab Vermir!")
        }
    }

    useEffect(() => {
        sessionStorage.removeItem(startDrawActive)
        const localTokenValue = localStorage.getItem(localToken)
        if (localTokenValue) return navigate('/')
    }, [])

    return (
        <div className="login_layout">
            <div className="login-section">
                <div className="login-container">
                    <h1 className="form-title">Hesabınıza daxil olun</h1>
                    <form className="form-container" onSubmit={handleSubmit}>
                        <label htmlFor="user_name">İstifadəçi</label>
                        <div className="input-block">
                            <input type="text" name="user_name" id="user_name" required autoComplete="off" />
                        </div>
                        <label htmlFor="password">Şifrə</label>
                        <div className="input-block">
                            <input type="password" name="password" id="password" required autoComplete="off" placeholder="********" />
                            <div className="password-hidden" onClick={passwordHidden}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.1083 7.89166L7.8916 12.1083C7.34994 11.5667 7.0166 10.825 7.0166 10C7.0166 8.35 8.34993 7.01666 9.99993 7.01666C10.8249 7.01666 11.5666 7.35 12.1083 7.89166Z" stroke="#8D7DAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.8499 4.80834C13.3915 3.70834 11.7249 3.10834 9.99987 3.10834C7.0582 3.10834 4.31654 4.84167 2.4082 7.84167C1.6582 9.01667 1.6582 10.9917 2.4082 12.1667C3.06654 13.2 3.8332 14.0917 4.66654 14.8083" stroke="#8D7DAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.0166 16.275C7.9666 16.675 8.97493 16.8917 9.99993 16.8917C12.9416 16.8917 15.6833 15.1583 17.5916 12.1583C18.3416 10.9833 18.3416 9.00834 17.5916 7.83334C17.3166 7.4 17.0166 6.99167 16.7083 6.60834" stroke="#8D7DAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.9252 10.5833C12.7085 11.7583 11.7502 12.7166 10.5752 12.9333" stroke="#8D7DAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.8915 12.1083L1.6665 18.3333" stroke="#8D7DAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.3334 1.66669L12.1084 7.89169" stroke="#8D7DAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <button type='submit' className="form-btn">Daxil ol</button>
                    </form>
                </div>
            </div>
        </div>
    )
}
