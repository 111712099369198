import { useEffect, useState } from "react"
import axios from 'axios'
import Sidebar from "../../components/sidebar"
import { toast } from 'react-toastify'
import "./index.css"

export default function DrawInformations() {
    const [drawInformations, setDrawInformations] = useState(null)
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL

    async function formSubmit(e) {
        e.preventDefault()

        const draw_slogan = e.target.elements.draw_slogan.value
        const draw_number = e.target.elements.draw_number.value

        try {
            const res = await axios.patch(`${baseApiUrl}/draw-informations/${drawInformations._id}`, {
                draw_slogan,
                draw_number
            });

            if (res.status === 200) {
                toast.success(res.data?.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Server Cavab Vermir!")
        }
    }

    async function getDrawInformations() {
        try {
            const res = await axios.get(`${baseApiUrl}/draw-informations`)

            if (res.status === 200) {
                document.getElementById("draw_slogan").value = res.data.draw_slogan
                document.getElementById("draw_number").value = res.data.draw_number
                setDrawInformations(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDrawInformations()
    }, [])

    return (
        <div className="draw_informations_layout">
            <Sidebar />
            <section className="draw-informations-page">
                <h1 className="draw-informations-title">Tiraj məlumatları</h1>
                <form onSubmit={formSubmit} className="draw-informations-form">
                    <label>Tiraj şüarı</label>
                    <input type="text" name="draw_slogan" id="draw_slogan" autoComplete="off" required />
                    <label>Tiraj nömrəsi</label>
                    <input type="text" name="draw_number" id="draw_number" autoComplete="off" required />
                    <button type="submit">Redaktə et</button>
                </form>
            </section>
        </div>
    )
}
