import { useEffect, useState } from "react"
import axios from "axios"
import { toast } from 'react-toastify'
import Sidebar from "../../components/sidebar"
import "./index.css"

export default function Winnings() {
    const [winnings, setWinnings] = useState([])
    const [editId, setEditId] = useState(null)
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL

    async function formSubmit(e) {
        e.preventDefault()

        const winning_title = e.target.elements.winning_title.value
        const winning_count = e.target.elements.winning_count.value
        const winning_img = e.target.winning_img.files[0]
        const formData = new FormData();

        if (winning_title) formData.append("winning_title", winning_title);
        if (winning_count) formData.append("winning_count", winning_count);
        if (winning_img) formData.append("winning_img", winning_img);

        try {
            const img = document.getElementById('imagePreview');

            if (!editId) {
                const res = await axios.post(`${baseApiUrl}/winning`, formData);

                if (res.status === 200) {
                    getAllWinnings()
                    toast.success(res.data?.message)
                    e.target.elements.winning_title.value = ''
                    e.target.elements.winning_count.value = ''
                    e.target.winning_img.value = ''
                    img.src = ''
                    setEditId(null)
                }

                return
            }

            const res = await axios.patch(`${baseApiUrl}/winning/${editId}`, formData);
            
            if (res.status === 200) {
                getAllWinnings()
                toast.success(res.data?.message)
                e.target.elements.winning_title.value = ''
                e.target.elements.winning_count.value = ''
                e.target.winning_img.value = ''
                img.src = ''
                setEditId(null)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Server Cavab Vermir!")
        }
    }

    async function getAllWinnings() {
        try {
            const res = await axios.get(`${baseApiUrl}/winning`)
            if (res.status === 200) {
                setWinnings(res.data.winnings)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function deleteWinning(id) {
        try {
            const res = await axios.delete(`${baseApiUrl}/winning/${id}`)
            if (res.status === 200) {
                getAllWinnings()
                toast.success(res.data?.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "Server Cavab Vermir!")
        }
    }

    async function editWinning(data) {
        const titleInput = document.getElementById('winning_title');
        const countInput = document.getElementById('winning_count');
        const imgInput = document.getElementById('winning_img');
        const img = document.getElementById('imagePreview');

        setEditId(data._id)
        document.getElementsByClassName("winnings-page")[0].scrollTo(0,0);

        titleInput.value = data?.winning_title
        countInput.value = data?.winning_count
        imgInput.value = ""
        img.src = data?.winning_img
    }

    function onChangeInput(e) {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(e) {
                const img = document.getElementById('imagePreview');
                img.src = e.target.result;
            }
            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        getAllWinnings()
    }, [])

    return (
        <div className="winnings_layout">
            <Sidebar />
            <section className="winnings-page">
                <h1 className="winning-title">Uduşlar</h1>
                <form className="winning-form-container" onSubmit={formSubmit}>
                    <div className="winning-left-part">
                        <label>Uduş şəkli</label>
                        <label htmlFor="winning_img" >
                            <div className="winning-img-container">
                                <p className="add-img">Yüklə</p>
                                <img id="imagePreview" src="" alt="" />
                            </div>
                        </label>
                        <input
                            style={{display: "none"}} 
                            type="file" name="winning_img" 
                            id="winning_img" 
                            accept=".png, .jpg, .jpeg, .webp" 
                            onChange={(e) => onChangeInput(e)}
                        />
                    </div>
                    <div className="winning-info-part">
                        <label>Uduş</label>
                        <input type="text" name="winning_title" id="winning_title" autoComplete="off" required />
                        <label>Uduş sayı</label>
                        <input type="number" min={1} name="winning_count" id="winning_count" autoComplete="off" required />
                        <button type="submit">{editId ? "Yadda saxla" : "Əlavə et"}</button>
                    </div>
                </form>
                <div className="winning-list">
                    {winnings?.length ? winnings?.map((item, index) => (
                        <div key={index} className="winning-container">
                            <div className="winning-img">
                                <img src={item?.winning_img} alt="" width={300} />
                            </div>
                            <div className="winning-details">
                                <span>Uduş:</span>
                                <p>{item?.winning_title}</p>
                                <span>Uduş sayı:</span>
                                <p>{item?.winning_count} ədəd</p>
                                <div className="winning-action-buttons">
                                    <button onClick={() => deleteWinning(item._id)}>Sil</button>
                                    <button onClick={() => editWinning(item)}>Redaktə et</button>
                                </div>
                            </div>
                        </div>
                    )) : <p className="empty-winning-notif">Uduş yoxdur!</p>}
                </div>
            </section>
        </div>
    )
}
