import "./index.css"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

export default function Sidebar() {
    const navigate = useNavigate()
    const localToken = process.env.REACT_APP_LOCAL_STORAGE
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL
    const picked_codes = process.env.REACT_APP_PICKED_CODES
    const currentCountWinning = process.env.REACT_APP_CURRENT_COUNT_WINNING
    const startDraw = process.env.REACT_APP_START_DRAW

    async function logoutHandler() {
        try {
            const res = await axios.delete(`${baseApiUrl}/unique-codes`);
            if (res.status === 200) {
                navigate('/login')
                localStorage.removeItem(localToken)
                sessionStorage.removeItem(picked_codes)
                sessionStorage.removeItem(currentCountWinning)
                sessionStorage.removeItem(startDraw)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='sidebar-container'>
            <p className='sidebar-container-title'>Panel</p>
            <Link to="/">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 10H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10 15V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="link-title">Siyahı əlavə et</p>
            </Link>
            <Link to="/winners">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.63338 9.05835C7.55005 9.05002 7.45005 9.05002 7.35838 9.05835C5.37505 8.99169 3.80005 7.36669 3.80005 5.36669C3.80005 3.32502 5.45005 1.66669 7.50005 1.66669C9.54172 1.66669 11.2 3.32502 11.2 5.36669C11.1917 7.36669 9.61671 8.99169 7.63338 9.05835Z" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M13.675 3.33331C15.2916 3.33331 16.5917 4.64165 16.5917 6.24998C16.5917 7.82498 15.3417 9.10831 13.7833 9.16665C13.7167 9.15831 13.6417 9.15831 13.5667 9.16665" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.4666 12.1333C1.44993 13.4833 1.44993 15.6833 3.4666 17.025C5.75827 18.5583 9.5166 18.5583 11.8083 17.025C13.8249 15.675 13.8249 13.475 11.8083 12.1333C9.52494 10.6083 5.7666 10.6083 3.4666 12.1333Z" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.2834 16.6667C15.8834 16.5417 16.4501 16.3 16.9168 15.9417C18.2168 14.9667 18.2168 13.3584 16.9168 12.3834C16.4584 12.0334 15.9001 11.8 15.3084 11.6667" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="link-title">Qaliblər</p>
            </Link>
            <Link to="/winnings">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.125 13.75V15.5" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.9585 18.3333H14.2918V17.5C14.2918 16.5833 13.5418 15.8333 12.6252 15.8333H7.62516C6.7085 15.8333 5.9585 16.5833 5.9585 17.5V18.3333V18.3333Z" stroke="#D9D9D9" strokeWidth="1.5" strokeMiterlimit="10"/>
                    <path d="M5.125 18.3333H15.125" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.99984 13.3334C6.77484 13.3334 4.1665 10.725 4.1665 7.50002V5.00002C4.1665 3.15835 5.65817 1.66669 7.49984 1.66669H12.4998C14.3415 1.66669 15.8332 3.15835 15.8332 5.00002V7.50002C15.8332 10.725 13.2248 13.3334 9.99984 13.3334Z" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.55853 9.70833C3.93353 9.50833 3.38353 9.14167 2.9502 8.70833C2.2002 7.875 1.7002 6.875 1.7002 5.70833C1.7002 4.54167 2.61686 3.625 3.78353 3.625H4.3252C4.15853 4.00833 4.0752 4.43333 4.0752 4.875V7.375C4.0752 8.20833 4.2502 8.99167 4.55853 9.70833Z" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.4419 9.70833C16.0669 9.50833 16.6169 9.14167 17.0502 8.70833C17.8002 7.875 18.3002 6.875 18.3002 5.70833C18.3002 4.54167 17.3836 3.625 16.2169 3.625H15.6752C15.8419 4.00833 15.9252 4.43333 15.9252 4.875V7.375C15.9252 8.20833 15.7502 8.99167 15.4419 9.70833Z" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="link-title">Uduşlar</p>
            </Link>
            <Link to="/draw-informations">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99984 18.3334C14.5832 18.3334 18.3332 14.5834 18.3332 10C18.3332 5.41669 14.5832 1.66669 9.99984 1.66669C5.4165 1.66669 1.6665 5.41669 1.6665 10C1.6665 14.5834 5.4165 18.3334 9.99984 18.3334Z" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10 6.66669V10.8334" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M9.99561 13.3333H10.0031" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="link-title">Tiraj məlumatları</p>
            </Link>
            <a onClick={logoutHandler}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.4999 10H3.0166" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M4.87492 7.20831L2.08325 9.99998L4.87492 12.7916" stroke="#D9D9D9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="link-title">Çıxış</p>
            </a>
        </div>
    )
}
