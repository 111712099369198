// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page {
    text-align: center;
    background: #FAF8FF;
    height: 100vh;
    padding-top: 100px;
}

.not-found-title {
    font-size: 35px;
    font-weight: 500;
    line-height: 45px;
    color: #000000;
    margin-bottom: 50px;
}

.not-found-page button {
    background: #198B07;
    border-radius: 16px;
    padding: 20px 50px;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: #FAF8FF;
}`, "",{"version":3,"sources":["webpack://./src/pages/not-found-page/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".not-found-page {\n    text-align: center;\n    background: #FAF8FF;\n    height: 100vh;\n    padding-top: 100px;\n}\n\n.not-found-title {\n    font-size: 35px;\n    font-weight: 500;\n    line-height: 45px;\n    color: #000000;\n    margin-bottom: 50px;\n}\n\n.not-found-page button {\n    background: #198B07;\n    border-radius: 16px;\n    padding: 20px 50px;\n    border: none;\n    outline: none;\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 25px;\n    color: #FAF8FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
