import { Link } from "react-router-dom"
import "./index.css"

const NotFoundPage = () => {
    return (
        <div className='not-found-page'>
            <div className='not-found-title'>Səhifə Tapılmadı!</div>
            <Link to="/" >
                <button>
                    Ana səhifəyə keçid edin
                </button>
            </Link>
        </div>
    )
}

export default NotFoundPage