import loadingImg from "../../assets/loading.gif"
import "./index.css"

export default function Loading() {
    return (
        <div className="loading-container">
            <img src={loadingImg} alt="" />
        </div>
    )
}
