// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-container {
    width: 240px;
    height: calc(100vh - 64px);
    padding: 24px;
    background: #24006B;
    border-radius: 16px;
    margin-right: 24px;
}

.sidebar-container-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #FFFFFF;
    text-align: center;
}

.sidebar-container a {
    display: flex;
    align-items: center;
    padding-top: 24px;
    margin-top: 24px;
    text-decoration: none;
    position: relative;
    z-index: 1;
    cursor: pointer;
}

.sidebar-container a::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: -1px;
    background: linear-gradient(90deg, rgba(144, 144, 144, 0.5) 0%, #D9D9D9 49.52%, rgba(144, 144, 144, 0.5) 99.04%);
    z-index: -1;
}

.link-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #D9D9D9;
    margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,SAAS;IACT,gHAAgH;IAChH,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".sidebar-container {\n    width: 240px;\n    height: calc(100vh - 64px);\n    padding: 24px;\n    background: #24006B;\n    border-radius: 16px;\n    margin-right: 24px;\n}\n\n.sidebar-container-title {\n    font-size: 24px;\n    font-weight: 600;\n    line-height: 36px;\n    color: #FFFFFF;\n    text-align: center;\n}\n\n.sidebar-container a {\n    display: flex;\n    align-items: center;\n    padding-top: 24px;\n    margin-top: 24px;\n    text-decoration: none;\n    position: relative;\n    z-index: 1;\n    cursor: pointer;\n}\n\n.sidebar-container a::before {\n    content: \"\";\n    position: absolute;\n    width: 100%;\n    height: 1px;\n    top: -1px;\n    background: linear-gradient(90deg, rgba(144, 144, 144, 0.5) 0%, #D9D9D9 49.52%, rgba(144, 144, 144, 0.5) 99.04%);\n    z-index: -1;\n}\n\n.link-title {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    color: #D9D9D9;\n    margin-left: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
