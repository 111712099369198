import Sidebar from "../../components/sidebar"
import { useEffect, useState } from "react"
import axios from 'axios'
import "./index.css"

export default function Winners() {
    const [pickedUniqueCodes, setPickedUniqueCodes] = useState([])

    async function getAllPickedUniqueCodes() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/picked-unique-codes`)
            
            if (res.status === 200) {
                setPickedUniqueCodes(res.data?.pickedUniqueCodes)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getExcelData() {
        try {  
            const res = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/json-to-excel`, {
                responseType: 'blob'
            })

            if (res.status === 200) {
                // Create a Blob from the response data
                const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                
                // Create a download link for the Blob
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url;
                a.download = 'qalib-unikal-kodlar.xlsx';
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
    
                // // Revoke the object URL to free up memory
                window.URL.revokeObjectURL(url)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAllPickedUniqueCodes()
    }, [])

    return (
        <div className="winners_layout">
            <Sidebar />
            <section className="winners-page">
                <h1 className="winners-title">Qaliblər</h1>
                {/* <div className="search-unique-code">
                    <div className="inner-search-container">
                        <input type="text" name="unique-code" id="unique-code" required autoComplete="off" placeholder='Unikal kod ilə axtarış' />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.58317 17.5C13.9554 17.5 17.4998 13.9556 17.4998 9.58332C17.4998 5.21107 13.9554 1.66666 9.58317 1.66666C5.21092 1.66666 1.6665 5.21107 1.6665 9.58332C1.6665 13.9556 5.21092 17.5 9.58317 17.5Z" stroke="#909090" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.3332 18.3333L16.6665 16.6667" stroke="#909090" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
                <div className="tiraj-code">
                    <div className="inner-search-container">
                        <select name="tiraj-number" id="tiraj-number">
                            <option value="0">Tiraj nömrəsi</option>
                            <option value="1">1-ci mərhələnin 1-ci tirajı</option>
                            <option value="2">1-ci mərhələnin 2-ci tirajı</option>
                            <option value="3">2-ci tiraj</option>
                        </select>
                    </div>
                </div> */}
                {pickedUniqueCodes?.length 
                    ? <div className="download-excel-file-btn">
                        <button onClick={getExcelData}>QALİB UNİKAL KODLARI YÜKLƏ</button>
                    </div> : ""
                }
                <div className="winners-code-titles">
                    <p>Unikal kod</p>
                    <p>Uduş</p>
                    <p>Tiraj nömrəsi</p>
                </div>
                <div className="winners-code">
                    {pickedUniqueCodes?.length ? pickedUniqueCodes.map((item, index) => (
                        <div key={index} className="winners-code-items">
                            <p>{item?.unique_code?.slice(0, 4)}-{item?.unique_code?.slice(4, 8)}-{item?.unique_code?.slice(8)}</p>
                            <p>{item?.winning}</p>
                            <p>{item?.draw}</p>
                        </div>
                    )) : <p className="empty-picked-codes">Qalib Unikal Kod Yoxdur</p>}
                </div>
            </section>
        </div>
    )
}
