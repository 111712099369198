import { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { axiosInterceptor } from './utils/axios-interceptor';
import AddList from './pages/add-list';
import Login from './pages/login';
import Winners from './pages/winners';
import Lottery from './pages/lottery';
import Winnings from './pages/winnings';
import DrawInformations from './pages/draw-informations';
import NotFoundPage from './pages/not-found-page';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const navigate = useNavigate()
  const windowLocation = useLocation().pathname;

  axiosInterceptor(navigate)

  useEffect(() => {
    if (windowLocation !== '/login') {
      const localToken = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)
      if (!localToken) return navigate('/login')
    }
  }, [windowLocation])

  return (
    <main>
      <ToastContainer position='top-right' />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<AddList />} />
        <Route path="/winners" element={<Winners />} />
        <Route path="/winnings" element={<Winnings />} />
        <Route path="/draw-informations" element={<DrawInformations />} />
        <Route path="/lottery" element={<Lottery />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </main>
  );
}

export default App;
