import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../components/loading'
import { toast } from 'react-toastify'
import "./index.css"
let winning_series = 0

export default function Lottery() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [drawInfo, setDrawInfo] = useState(null)
    const [allWinnings, setAllWinnings] = useState([])
    const [currentWinning, setCurrentWinning] = useState(null)
    const [uniqueCodes, setUniqueCodes] = useState([])
    const [uniqueCodesCount, setUniqueCodesCount] = useState([])
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL
    const picked_codes = process.env.REACT_APP_PICKED_CODES
    const currentCountWinning = process.env.REACT_APP_CURRENT_COUNT_WINNING
    const startDraw = process.env.REACT_APP_START_DRAW

    async function randomPickedUniqueCodes() {
        setLoading(true)
        try {
            const res = await axios.post(`${baseApiUrl}/picked-unique-codes`, {
                count: currentWinning.winning_count,
                winning: currentWinning.winning_title,
                draw: drawInfo?.draw_number
            })

            if (res.status === 200) {
                setUniqueCodes(res.data?.winners)
                setLoading(false)
                document.getElementById("start_picked").disabled = true
                document.getElementById("next_winning").disabled = false
                sessionStorage.setItem(picked_codes, JSON.stringify(res.data?.winners))
            }
        } catch (error) {
            setLoading(false)
            toast.error(error?.response?.data?.message || "Server Cavab Vermir!")
        }
    }

    async function getAllWinnings() {
        try {
            const res = await axios.get(`${baseApiUrl}/winning`)
            if (res.status === 200) {
                setCurrentWinning(res.data.winnings[winning_series])
                setAllWinnings(res.data.winnings)
                setUniqueCodesCount(Array.from({length: res.data.winnings[winning_series]?.winning_count}, (_, i) => i + 1))
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getDrawInformations() {
        try {
            const res = await axios.get(`${baseApiUrl}/draw-informations`)
            if (res.status === 200) {
                setDrawInfo(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function nextWinningHandler() {
        if (winning_series < allWinnings.length - 1) {
            winning_series++
            setUniqueCodes([])
            setCurrentWinning(allWinnings[winning_series])
            setUniqueCodesCount(Array.from({length: allWinnings[winning_series]?.winning_count}, (_, i) => i + 1))
            document.getElementById("start_picked").disabled = false
            document.getElementById("next_winning").disabled = true
            sessionStorage.setItem(currentCountWinning, winning_series)
            sessionStorage.removeItem(picked_codes)
        }
    }

    function checkSessionStorage() {
        const current_winning_series = sessionStorage.getItem(currentCountWinning)
        const sessionPickedCodes = sessionStorage.getItem(picked_codes)
    
        if (sessionPickedCodes) {
            setUniqueCodes(JSON.parse(sessionPickedCodes))
            document.getElementById("start_picked").disabled = true
            document.getElementById("next_winning").disabled = false
        } else if (!sessionPickedCodes) {
            document.getElementById("next_winning").disabled = true
        }
    
        if (current_winning_series) {
            winning_series = +current_winning_series
        }
    
        if (!winning_series && !sessionPickedCodes) {
            document.getElementById("next_winning").disabled = true
        }
    }

    useEffect(() => {
        const startDrawSession = sessionStorage.getItem(startDraw)
        if (!startDrawSession) {
            navigate("/")
        } 
        checkSessionStorage()
        getAllWinnings()
        getDrawInformations()

        return () => {
            sessionStorage.removeItem(startDraw);
        };
    }, [])

    return (
        <section className="lottery-page">
            <div className="lottery-page-container">
                <h1>{drawInfo && drawInfo?.draw_slogan}</h1>
                <div className='lottery-page-details'>
                    <div className='lottery-page-details-left'>
                        {currentWinning && <div className='current_winning'>
                            <div className='current_winning_img'>
                                <img src={currentWinning?.winning_img} alt="" />
                            </div>
                            <p className='current_winning_title'>{currentWinning?.winning_title}</p>
                        </div>}
                    </div>
                    <div className='lottery-page-details-right'>
                        <p className='actions-title'>{drawInfo && drawInfo?.draw_number}</p>
                        <div className="lottery-actions">
                            <div className='button-container'>
                                <button id="start_picked" onClick={randomPickedUniqueCodes}>
                                    {loading ? <Loading /> : <p>Başla</p>}
                                </button>
                                <button id="next_winning" onClick={nextWinningHandler}><p>Təbriklər!</p></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lottery-unique-codes-container">
                    <p>Unikal kodlar</p>
                    <ul style={{justifyContent: uniqueCodesCount?.length < 2 && 'center'}} className="lottery-unique-codes-list">
                        {uniqueCodes?.length ? uniqueCodes?.map((item, index) => (
                            <li style={{width: uniqueCodesCount?.length <= 2 && '49%'}} key={index}>{item?.unique_code?.slice(0, 4)}-{item?.unique_code?.slice(4, 8)}-{item?.unique_code?.slice(8)}</li>
                        )) : uniqueCodesCount?.map((item, index) => (
                            <li style={{width: uniqueCodesCount?.length <= 2 && '49%'}} key={index}></li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}
