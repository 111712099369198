import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import Sidebar from "../../components/sidebar"
import axios from "axios"
import { toast } from 'react-toastify'
import "./index.css"

export default function AddList() {
    const [uniqueCodes, setUniqueCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    let cachedCodes = []
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL
    const picked_codes = process.env.REACT_APP_PICKED_CODES
    const currentCountWinning = process.env.REACT_APP_CURRENT_COUNT_WINNING
    const startDrawActive = process.env.REACT_APP_START_DRAW

    async function handleSubmit(e) {
        e.preventDefault();
        setIsLoading(true)
        cachedCodes = uniqueCodes
        setUniqueCodes([])
        document.getElementsByClassName("add-list-button")[0].disabled = true

        const excelFile = e.target.file.files[0]
        const formData = new FormData()

        formData.append("excelFile", excelFile)

        try {
            const res = await axios.post(`${baseApiUrl}/unique-codes`, formData)
            if (res.status === 200) {
                const fileInput = document.getElementById('file');
                fileInput.value=""
                // document.getElementsByClassName("add-list-button")[0].disabled = true
                getAllUniqueCodes()
                toast.success(res.data?.message)
                sessionStorage.removeItem(picked_codes)
                sessionStorage.removeItem(currentCountWinning)
                sessionStorage.removeItem(startDrawActive)
            }
        } catch (error) {
            setUniqueCodes(cachedCodes)
            setIsLoading(false)
            toast.error(error?.response?.data?.message || "Server Cavab Vermir!")
        }
    }

    async function getAllUniqueCodes() {
        setIsLoading(true)
        try {
            const res = await axios.get(`${baseApiUrl}/unique-codes`);
            if (res.status === 200) {
                setUniqueCodes(res.data?.uniqueCodes)
                document.getElementsByClassName("add-list-button")[1].disabled = false
                setIsLoading(false)
                return
            }
            setIsLoading(false)
            document.getElementsByClassName("add-list-button")[1].disabled = true
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    function onChangeHandler(e) {
        if (e.target.value) {
            document.getElementsByClassName("add-list-button")[0].disabled = false
        } else {
            document.getElementsByClassName("add-list-button")[0].disabled = true
        }
    }

    function startDraw() {
        sessionStorage.setItem(startDrawActive, "ok")
        navigate("/lottery")
    }

    useEffect(() => {
        document.getElementsByClassName("add-list-button")[0].disabled = true
        getAllUniqueCodes()
    }, [])

    return (
        <div className="add_list">
            <Sidebar />
            <section className="add-list-page">
                <h1 className="add-list-title">Siyahı</h1>
                <div className="add-list-container">
                    <p className="container-title">Siyahı yüklə</p>
                    <form className="inner-container" onSubmit={handleSubmit}>
                        <input 
                            onChange={(e) => onChangeHandler(e)} 
                            type="file" 
                            name="file" id="file" 
                            required
                            accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        <button className="add-list-button" type="submit">Yüklə</button>
                    </form>
                </div>
                <div className="start-container">
                    <p className="container-title">Tiraja başla</p>
                    <div className="inner-container">
                        <button onClick={startDraw} className="add-list-button">Başla</button>
                    </div>
                </div>
                <div className="unique-code-contaier">
                    <p className="container-title">Unikal kodlar</p>
                    <ul className="unique-code-list">
                        {uniqueCodes?.length ? uniqueCodes.map((item, index) => (
                            <li key={index} className="unique-code-item">{item?.unique_code?.slice(0, 4)}-{item?.unique_code?.slice(4, 8)}-{item?.unique_code?.slice(8)}</li>
                        )) : <p className='showing-empty-unique-codes'>{isLoading ? "Siyahı Yüklənir ..." : "Unikal Kod Yoxdur"}</p>}
                    </ul>
                </div>
            </section>
        </div>
    )
}
